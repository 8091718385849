import {
    BrowserStorageContext,
    FavoriteEventsProvider,
    FeedContext,
} from "@finbackoffice/site-core";
import {
    createContext,
    Dispatch,
    useMemo,
    FC,
    PropsWithChildren,
    useState,
    SetStateAction,
    useContext,
} from "react";
import { EventStatus } from "@finbackoffice/enums";
import { useSocketStorage, Feed } from "hooks";

export interface IInitialDataContext {
    currentGameStatus: EventStatus.Prematch | EventStatus.Live | null;
    setCurrentGameStatus: (val: EventStatus.Live | EventStatus.Prematch | null) => void;
    activeGame: string | null;
    setActiveGame: Dispatch<SetStateAction<string | null>>;
    setSiteHeaderHeight: (val: number) => void;
    siteHeaderHeight: number;
}

export const InitialDataContext = createContext<IInitialDataContext>(null as any);

export const InitialDataProvider: FC<PropsWithChildren> = ({ children }) => {
    const [activeGame, setActiveGame] = useState<string | null>(null);
    const [siteHeaderHeight, setSiteHeaderHeight] = useState(0);
    const [currentGameStatus, setCurrentGameStatus] = useState<
        EventStatus.Live | EventStatus.Prematch | null
    >(null);
    const socketStorage = useSocketStorage(Feed.live);
    const { hasLiveFeedGames } = useContext(FeedContext);
    const browserStorage = useContext(BrowserStorageContext);

    const value = useMemo(
        () => ({
            siteHeaderHeight,
            activeGame,
            setActiveGame,
            setSiteHeaderHeight,
            setCurrentGameStatus,
            currentGameStatus,
        }),
        [siteHeaderHeight, activeGame, currentGameStatus],
    );

    return (
        <InitialDataContext.Provider value={value}>
            <FavoriteEventsProvider
                browserStorage={browserStorage}
                socketStorage={socketStorage}
                liveFeedReady={hasLiveFeedGames}>
                {children}
            </FavoriteEventsProvider>
        </InitialDataContext.Provider>
    );
};
