import { InferType, object, string } from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC, useCallback, useEffect, useState } from "react";
import Countdown from "react-countdown";
import classnames from "classnames";
// import {
//     AuthContext,
//     ClientBFFContext,
//     // useRuntimeConfig,
//     useTranslation,
// } from "@finbackoffice/site-core";
import { IOtpResponse } from "@finbackoffice/clientbff-client";
import Button from "components/base/button/Button";
import Translate from "components/base/translate/Translate";
import Input from "components/base/input-field/Input";
import { Svg } from "components/base/svg/Svg";
import styles from "./verification-kyc-item.module.sass";

interface IProps {
    type: string;
    data: string;
    verified: boolean;
    onVerified: () => void;
}

const verificationValidationSchema = object({
    code: string().required().min(6),
});

export type IVerificationItemForm = InferType<typeof verificationValidationSchema>;

const VerificationKycItem: FC<IProps> = ({ type, data, verified, onVerified }) => {
    // const client = useContext(ClientBFFContext);
    // const { t } = useTranslation();
    // const { setToken } = useContext(AuthContext);
    const [isVerificationFormShow, setIsVerificationFormShow] = useState(false);
    const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(verified);
    const [loading, setLoading] = useState(false);
    // const [tempVerificationId, setTempVerificationId] = useState("");
    const [validUntil, setValidUntil] = useState<number>();
    // const ASSETS_URL = useRuntimeConfig("ASSETS_URL");

    const {
        control,
        handleSubmit,
        // setError,
        clearErrors,
        formState: { isValid, isSubmitting, errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(verificationValidationSchema),
        defaultValues: {
            code: "",
        },
    });

    useEffect(() => {
        if (isValid) {
            clearErrors();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);

    const onSubmit = useCallback(
        async (data: IVerificationItemForm) => {
            console.log(data, "submit");
            onVerified();
            setIsVerificationCodeSent(true);
            setIsVerificationFormShow(false);
            if (isValid && !isSubmitting && !loading) {
                // const req = {
                //     id: tempVerificationId,
                //     code: Object.values(data).join(""),
                // };
                // if (type === "email") {
                //     try {
                //         const response = await client.checkEmail(req);
                //
                //         if (response.token) {
                //             setToken(response.token);
                //         }
                //     } catch (err: any) {
                //         const error = err.response?.data;
                //
                //         if (error.error === "registration_error") {
                //             setError("root.apiError", {
                //                 type: "wrongCode",
                //                 message: t("verification_error_wrongCode"),
                //             });
                //         }
                //     }
                // } else if (type === "phone") {
                //     try {
                //         const response = await client.checkPhone(req);
                //
                //         if (response.token) {
                //             setToken(response.token);
                //         }
                //     } catch (err: any) {
                //         const error = err.response?.data;
                //
                //         if (error.error === "registration_error") {
                //             setError("root.apiError", {
                //                 type: "wrongCode",
                //                 message: t("verification_error_wrongCode"),
                //             });
                //         }
                //     }
                // }
            }
        },
        // @TODO check deps
        [isSubmitting, isValid, loading, onVerified],
    );

    const handleRequestCode = useCallback(() => {
        const handleResponse = (res: IOtpResponse) => {
            // setTempVerificationId(res.id);
            setValidUntil(new Date(res.valid_until).getTime());
            setIsVerificationCodeSent(true);
            setLoading(false);
        };
        if (!isSubmitting && !loading) {
            setLoading(true);
            if (type === "email") {
                try {
                    // @TODO do actual request
                    // const response = await client.validateEmail();
                    handleResponse({ id: "123", valid_until: "10000" });
                } catch (err: any) {
                    const error = err.response?.data;
                    console.error(error);
                    setLoading(false);
                }
            } else if (type === "phone") {
                try {
                    // @TODO do actual request
                    // const response = await client.validatePhone();
                    handleResponse({ id: "123", valid_until: "10000" });
                } catch (err: any) {
                    const error = err.response?.data;
                    console.error(error);
                    setLoading(false);
                }
            }
        }
        // @TODO check deps
    }, [isSubmitting, loading, type]);

    const getInfo = useCallback(() => {
        return (
            <div className={styles.userInfo}>
                <Svg
                    src={`/common/desktop/base-icons/${type === "phone" ? "user-mobile" : "account-messages"}.svg`}
                    wrapper="span"
                    className="svg-icon"
                />
                {data}
            </div>
        );
    }, [data, type]);

    const renderContent = useCallback(({ minutes, seconds, completed }: any) => {
        if (completed) {
            return null;
        }

        return (
            <p className={styles.resendTxt}>
                <Translate
                    tid="verification_newCodeResend"
                    replace={{
                        minutes,
                        seconds,
                    }}
                />
            </p>
        );
    }, []);

    const onCompleteHandler = () => {
        setIsVerificationCodeSent(false);
    };

    return (
        <div className={classnames(styles.block, isVerificationFormShow && styles.opened)}>
            <div className={styles.verifiedView}>
                {getInfo()}
                {!isVerificationFormShow &&
                    (verified ? (
                        <Svg
                            className={styles.iconVerified}
                            src="/common/desktop/base-icons/account-save.svg"
                            wrapper="span"
                        />
                    ) : (
                        <Button
                            type="submit"
                            loading={loading}
                            className={styles.verifyBtn}
                            disabled={isSubmitting || loading}
                            onClick={() => {
                                setIsVerificationFormShow(true);
                            }}
                            variant="primary">
                            <Translate tid="verification_verify" />
                        </Button>
                    ))}
                {/*<Translate tid={`verification_${type}SuccessMsg`} />*/}
            </div>

            {isVerificationFormShow && (
                <>
                    <form className={styles.verifyForm} onSubmit={handleSubmit(onSubmit)}>
                        <Translate
                            tid={
                                type === "phone"
                                    ? "verification_kyc_phone_description"
                                    : "verification_email_description"
                            }
                            replace={{ [type]: data }}
                            dangerous>
                            <p className={styles.verificationTxt} />
                        </Translate>
                        <div className={styles.verifyFormFields}>
                            <Controller
                                render={({ field: { onChange, value, name } }) => {
                                    return (
                                        <Input
                                            type="number"
                                            valueAsNumber={false}
                                            onChange={onChange}
                                            value={value}
                                            name={name}
                                            disabled={!isVerificationCodeSent}
                                            label="verification_verificationCode"
                                        />
                                    );
                                }}
                                control={control}
                                name="code"
                            />

                            {!isVerificationCodeSent && (
                                <Button
                                    type="button"
                                    loading={loading}
                                    disabled={isSubmitting || loading}
                                    variant="secondary"
                                    onClick={handleRequestCode}>
                                    <Translate tid="verification_requestCode" />
                                </Button>
                            )}
                            {isVerificationCodeSent && (
                                <Button
                                    type="submit"
                                    loading={loading}
                                    disabled={!isValid || isSubmitting || loading}
                                    className={styles.verifyBtn}>
                                    <Translate tid="verification_verify" />
                                </Button>
                            )}
                        </div>
                        {isVerificationCodeSent && !errors.root?.apiError && (
                            <Countdown
                                date={validUntil}
                                renderer={renderContent}
                                onComplete={onCompleteHandler}
                            />
                        )}
                        {errors.root?.apiError && (
                            <p className={styles.wrongTxt}>{errors.root.apiError.message}</p>
                        )}
                    </form>
                </>
            )}
        </div>
    );
};
export default VerificationKycItem;
