import { useCallback, useContext, useEffect, useMemo } from "react";
import { getTimefilterPayloadForSocket } from "@finbackoffice/fe-core";
import { FeedContext, useWebsocketUpdates } from "@finbackoffice/site-core";
import { IClientError, ISocketResponse } from "@finbackoffice/websocket-client";
import { PrematchContext } from "contexts";
import { Feed, useSocketStorage } from "hooks";

// NOTICE: RUN ONLY ONCE
export const usePrematchSports = (): void => {
    const { getSports, getTopLeagues, connected } = useSocketStorage(Feed.prematch);
    const { timeFilter } = useContext(PrematchContext);
    const { setPrematch } = useContext(FeedContext);

    const handleSetPrematch = useCallback(
        (_nothing: ISocketResponse<"prematch">, error: IClientError) => {
            if (!error) {
                const sports = getSports();
                const topLeagues = getTopLeagues();
                setPrematch({
                    sports,
                    loading: false,
                    topLeagues,
                });
            } else {
                console.error(error);
            }
        },
        [getSports, getTopLeagues, setPrematch],
    );

    const payload = useMemo(
        () => (timeFilter ? getTimefilterPayloadForSocket(timeFilter) : null),
        [timeFilter],
    );

    const { subscribe, unsubscribe } = useWebsocketUpdates(
        "prematch",
        handleSetPrematch,
        false,
        payload,
    );

    useEffect(() => {
        if (connected) {
            subscribe();
        }

        return () => {
            if (connected) {
                unsubscribe();
                setPrematch({ sports: [], loading: true, topLeagues: [] });
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connected, timeFilter]);
};
