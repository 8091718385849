import classnames from "classnames";
import {
    SyntheticEvent,
    useEffect,
    useRef,
    useCallback,
    useState,
    HTMLInputTypeAttribute,
    InputHTMLAttributes,
} from "react";
import { FieldError } from "react-hook-form";
import { TranslationScopes } from "@finbackoffice/enums";
import { ITranslateProps } from "@finbackoffice/site-core";
import Translate from "../translate/Translate";
import Tooltip from "../tooltip/Tooltip";
import styles from "./input-field.module.sass";

interface Props<TType = HTMLInputTypeAttribute, TValueAsNumber = boolean> {
    label?: string | ITranslateProps;
    iconClass?: string;
    innerIconClass?: string;
    wrapperClassname?: string;
    error?: Partial<FieldError> | { type: string; message: string };
    tooltip?: {
        render: string;
        variant?: string;
        condition?: string;
    };
    onInnerIconClick?: (e: SyntheticEvent) => void;
    onIconClick?: (e: SyntheticEvent) => void;
    required?: boolean;
    enableAutoComplete?: boolean;
    showFocus?: boolean;
    forwardRef?: (input: HTMLInputElement | null) => void;
    onChange?: (
        val: TType extends "number"
            ? TValueAsNumber extends true | undefined
                ? number
                : string
            : string,
    ) => void;
    value?: TType extends "number"
        ? TValueAsNumber extends true | undefined
            ? number
            : string
        : string;
    valueAsNumber?: TValueAsNumber;
    name: string;
    type?: TType;
}

const Input = <TType extends HTMLInputTypeAttribute, TValueAsNumber extends boolean>({
    label,
    iconClass,
    innerIconClass,
    wrapperClassname,
    error,
    tooltip,
    onInnerIconClick,
    onIconClick,
    required,
    enableAutoComplete,
    showFocus,
    forwardRef,
    value,
    onChange,
    type,
    name,
    className,
    valueAsNumber,
    ...rest
}: Props<TType, TValueAsNumber> & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">) => {
    const _valueAsNumber = valueAsNumber ?? (type === "number" ? true : false);
    const [autoComplete, setAutoComplete] = useState(enableAutoComplete ? "on" : "off");
    const inputRef = useRef<any>();

    const handleRefSet = useCallback(
        (input: HTMLInputElement | null) => {
            inputRef.current = input;
            forwardRef?.(input);
        },
        [inputRef, forwardRef],
    );

    useEffect(() => {
        if (!enableAutoComplete && navigator?.userAgent.includes("Chrome")) {
            setAutoComplete("new-password");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (showFocus) {
            inputRef.current.focus();
        }
    }, [showFocus]);

    const handleInputChange = useCallback(
        (e: SyntheticEvent<HTMLInputElement>) => {
            type ValueType = TType extends "number"
                ? TValueAsNumber extends true | undefined
                    ? number
                    : string
                : string;
            if (type === "number" && _valueAsNumber) {
                const val = e.currentTarget.valueAsNumber;
                onChange?.(val as ValueType);
            } else {
                const val = e.currentTarget.value;
                onChange?.(val as ValueType);
            }
        },
        [_valueAsNumber, onChange, type],
    );

    return (
        <div className={wrapperClassname}>
            {iconClass && <i className={iconClass} onClick={onIconClick} />}
            {label && (
                <label htmlFor={name} className={styles.label}>
                    <Translate
                        tid={typeof label === "string" ? label : (label as ITranslateProps).tid}
                        namespace={
                            typeof label !== "string"
                                ? (label as ITranslateProps).namespace
                                : TranslationScopes.Common
                        }
                    />
                    :
                </label>
            )}
            <div className={classnames(error && styles.fieldError, required && "required")}>
                <input
                    ref={handleRefSet}
                    type={type}
                    id={name}
                    name={name}
                    onChange={handleInputChange}
                    value={_valueAsNumber ? value || "" : value}
                    className={className || styles.inputStyle}
                    autoComplete={autoComplete}
                    {...rest}
                />
                {innerIconClass && <i className={innerIconClass} onClick={onInnerIconClick} />}
                {error && (
                    <span className={classnames(styles.error, "field-error")}>{error.message}</span>
                )}
                {tooltip && (
                    <Tooltip
                        targetRef={inputRef}
                        message={tooltip.render}
                        variant={tooltip.variant}
                    />
                )}
            </div>
        </div>
    );
};

export default Input;
