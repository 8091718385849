import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useRouter } from "next/router";
import {
    IBEMarketFeedResponse,
    IClientError,
    IEventFeedResponse,
} from "@finbackoffice/websocket-client";
import { IMarketOutcomeFeedResponse } from "@finbackoffice/clientbff-client";
import {
    MarketGroupIds,
    TemplateIdsMap,
    sortSetWinnerMarkets,
    sortByNumberBetweenBrackets,
    sortByNumberAfterLastSpace,
    sortByFavoriteId,
    MarketGroupData,
    getMarketGroupId,
} from "@finbackoffice/fe-core";
import {
    BetSlipContext,
    BrowserStorageContext,
    BrowserStorageIdsEnum,
    useWebsocketUpdates,
} from "@finbackoffice/site-core";
import { EventStatus, MarketStatus, MarketOutcomeStatus } from "@finbackoffice/enums";
import { InitialDataContext, MarketUpdatesContext } from "contexts";
import { IGameData } from "types/GameData";
import { Feed } from "hooks";
import { RouteNames } from "utils/constants";
import { useGetCurrentFeedType } from "./useGetCurrentFeed";

const filterIdsByTemplateId = (ids: string[], templateId: number) =>
    ids.filter((id) => id.includes(templateId.toString()));

export const useGame = (gameId: number) => {
    const router = useRouter();
    const { setCurrentGameStatus } = useContext(InitialDataContext);
    const { get, set } = useContext(BrowserStorageContext);
    const { addBetItem, removeBetItem, isOutcomeSelected } = useContext(BetSlipContext);
    const [gameData, setGameData] = useState<IGameData | null>(null);
    const [favoriteMarketsIds, setFavoriteMarketsIds] = useState<string[]>([]);
    const [markets, setMarkets] = useState<MarketGroupData[]>([]);
    const { subscribeMarket, unsubscribeMarket } = useContext(MarketUpdatesContext);
    const feed = useGetCurrentFeedType();

    const isInitial = useRef(false);

    const sportId = gameData?.sport_id;
    const homeName = gameData?.home_team?.name || "";
    const awayName = gameData?.away_team?.name || "";

    const gameUpdateHandler = useCallback(
        (data: IEventFeedResponse, error: IClientError) => {
            if (!error) {
                const result: IGameData = { ...data, market_groups: {} };

                result.markets?.sort(sortSetWinnerMarkets);

                result.markets?.forEach((market: IBEMarketFeedResponse) => {
                    if (MarketGroupIds.indexOf(market.template_id) !== -1) {
                        if (result.market_groups.hasOwnProperty(getMarketGroupId(market))) {
                            result.market_groups[getMarketGroupId(market)].markets.push(market);
                        } else {
                            result.market_groups[getMarketGroupId(market)] = new MarketGroupData(
                                market,
                                getMarketGroupId(market),
                                result.home_team?.name || "",
                                result.away_team?.name || "",
                            );
                        }
                    } else {
                        result.market_groups[market.id] = new MarketGroupData(
                            market,
                            market.id.toString(),
                            result.home_team?.name || "",
                            result.away_team?.name || "",
                        );
                    }
                });

                result.markets = [];
                const groupIds = Object.keys(result.market_groups);

                [
                    TemplateIdsMap.HANDICAP,
                    TemplateIdsMap.HANDICAP_INCL_EXTRA_INNINGS,
                    TemplateIdsMap.INNINGS_1TO5_HANDICAP,
                    ...filterIdsByTemplateId(groupIds, TemplateIdsMap.INNINGNUMBER_INNING_HANDICAP),
                    ...filterIdsByTemplateId(
                        groupIds,
                        TemplateIdsMap.INNINGS_FROMVALUE_TOVALUE_HANDICAP,
                    ),
                    ...filterIdsByTemplateId(
                        groupIds,
                        TemplateIdsMap.QUARTERNUMBER_QUARTER_HANDICAP,
                    ),
                    ...filterIdsByTemplateId(groupIds, TemplateIdsMap.PERIODNUMBER_PERIOD_HANDICAP),
                ].forEach((item) => {
                    result.market_groups[item]?.markets.sort((a, b) =>
                        sortByNumberBetweenBrackets(a, b),
                    );
                });

                [
                    TemplateIdsMap.TOTAL,
                    TemplateIdsMap.COMPETITOR1_TOTAL,
                    TemplateIdsMap.COMPETITOR2_TOTAL,
                    TemplateIdsMap.TOTAL_1ST_HALF,
                    TemplateIdsMap.TOTAL_1ST_HALF_COMPETITOR1,
                    TemplateIdsMap.TOTAL_1ST_HALF_COMPETITOR2,
                    TemplateIdsMap.TOTAL_2ND_HALF,
                    TemplateIdsMap.TOTAL_2ND_HALF_COMPETITOR1,
                    TemplateIdsMap.TOTAL_2ND_HALF_COMPETITOR2,
                    TemplateIdsMap.TOTAL_POINTS,
                    TemplateIdsMap.TOTAL_INCL_EXTRA_INNINGS,
                    TemplateIdsMap.INNINGS_1TO5_TOTAL,
                    TemplateIdsMap.INNINGS_1TO5_COMPETITOR1_TOTAL,
                    TemplateIdsMap.INNINGS_1TO5_COMPETITOR2_TOTAL,
                    ...filterIdsByTemplateId(groupIds, TemplateIdsMap.INNINGNUMBER_INNING_TOTAL),
                    ...filterIdsByTemplateId(
                        groupIds,
                        TemplateIdsMap.INNINGS_FROMVALUE_TOVALUE_TOTAL,
                    ),
                    ...filterIdsByTemplateId(groupIds, TemplateIdsMap.QUARTERNUMBER_QUARTER_TOTAL),
                    ...filterIdsByTemplateId(
                        groupIds,
                        TemplateIdsMap.QUARTERNUMBER_QUARTER_COMPETITOR1_TOTAL,
                    ),
                    ...filterIdsByTemplateId(
                        groupIds,
                        TemplateIdsMap.QUARTERNUMBER_QUARTER_COMPETITOR2_TOTAL,
                    ),
                    ...filterIdsByTemplateId(groupIds, TemplateIdsMap.PERIODNUMBER_PERIOD_TOTAL),
                    ...filterIdsByTemplateId(
                        groupIds,
                        TemplateIdsMap.INNINGS_FROMVALUE_TOVALUE_COMPETITOR1_TOTAL,
                    ),
                    ...filterIdsByTemplateId(
                        groupIds,
                        TemplateIdsMap.INNINGS_FROMVALUE_TOVALUE_COMPETITOR2_TOTAL,
                    ),
                ].forEach((item) => {
                    result.market_groups[item]?.markets.sort((a, b) =>
                        sortByNumberAfterLastSpace(a, b),
                    );
                });

                setGameData(result);
            } else if (error.statusCode === 404) {
                router.replace({
                    pathname: `/${RouteNames.SPORT}/${feed}`,
                });
            }
        },
        [feed, router],
    );
    const payload = useMemo(() => ({ event_id: gameId }), [gameId]);
    useWebsocketUpdates("subscribe_event", gameUpdateHandler, true, payload);

    useEffect(() => {
        return () => {
            if (gameId) {
                setGameData(null);
            }
        };
    }, [gameId]);

    useEffect(() => {
        if (feed === Feed.live) {
            const savedFavMarkets = get(BrowserStorageIdsEnum.FAVORITE_LIVE_MARKETS_IDS);
            if (gameId && savedFavMarkets && sportId) {
                setFavoriteMarketsIds(savedFavMarkets[sportId] || []);
            }
        } else if (feed === Feed.prematch) {
            const savedFavMarkets = get(BrowserStorageIdsEnum.FAVORITE_PREMATCH_MARKETS_IDS);
            if (gameId && savedFavMarkets && sportId) {
                setFavoriteMarketsIds(savedFavMarkets[sportId] || []);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feed, gameId, sportId]);

    useEffect(() => {
        const updateMarkets = () => {
            const gameMarketGroups: MarketGroupData[] = gameData?.market_groups
                ? Object.values(gameData.market_groups)
                : [];

            gameMarketGroups.sort(sortByFavoriteId(favoriteMarketsIds));

            setMarkets(gameMarketGroups);
        };

        if (gameId && gameData) {
            updateMarkets();

            if (
                [
                    EventStatus.Ended,
                    EventStatus.Live,
                    EventStatus.Suspended,
                    EventStatus.Settled,
                ].indexOf(gameData.status) !== -1
            ) {
                setCurrentGameStatus(EventStatus.Live);
            } else {
                setCurrentGameStatus(EventStatus.Prematch);
            }

            if (feed === Feed.live) {
                if (
                    gameData.status === EventStatus.Settled ||
                    (!isInitial.current &&
                        (gameData.status === EventStatus.Ended ||
                            gameData.status === EventStatus.Prematch))
                ) {
                    router.replace(`/${RouteNames.SPORT}/${RouteNames.LIVE}`);
                }
            }
        }

        if (gameId && !isInitial.current) {
            isInitial.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameData, favoriteMarketsIds, gameId, feed]);

    const addBet = useCallback(
        (
            marketGroupId: string,
            marketName: string,
            marketStatus: MarketStatus,
            marketId: number,
            outcome: IMarketOutcomeFeedResponse,
        ) => {
            if (!isOutcomeSelected(outcome.id)) {
                addBetItem({
                    gameId,
                    marketGroupId,
                    marketId,
                    outcomeId: outcome.id,
                    team1Name: homeName,
                    team2Name: awayName,
                    marketName,
                    marketStatus,
                    outcomeName: outcome.name ?? "",
                    outcomeValue: outcome.odds,
                    outcomeStatus: MarketOutcomeStatus.Active,
                    outcomeInitValue: outcome.odds,
                    stake: "",
                    loading: false,
                    accepted: false,
                });
                subscribeMarket(gameId, marketId, outcome.id);
            } else {
                removeBetItem(outcome.id);
                unsubscribeMarket(outcome.id);
            }
        },
        [
            awayName,
            isOutcomeSelected,
            gameId,
            homeName,
            subscribeMarket,
            unsubscribeMarket,
            addBetItem,
            removeBetItem,
        ],
    );

    const getUpdatedFavorites = useCallback(
        (storage: Storage, marketType: string) => {
            if (sportId) {
                if (storage && storage.hasOwnProperty(sportId)) {
                    const index: number = storage[sportId].indexOf(marketType);

                    if (index === -1) {
                        storage[sportId].push(marketType);
                    } else {
                        storage[sportId].splice(index, 1);
                    }
                } else {
                    storage[sportId] = [marketType];
                }
            }

            return storage;
        },
        [sportId],
    );

    const onFavoriteItemClick: (marketType: string) => void = useCallback(
        (marketType: string): void => {
            if (sportId) {
                const savedFavPrematchMarkets = get(
                    BrowserStorageIdsEnum.FAVORITE_PREMATCH_MARKETS_IDS,
                );
                const savedFavLiveMarkets = get(BrowserStorageIdsEnum.FAVORITE_LIVE_MARKETS_IDS);
                let storage =
                    feed === Feed.prematch
                        ? (savedFavPrematchMarkets ?? {})
                        : (savedFavLiveMarkets ?? {});

                storage = getUpdatedFavorites(storage, marketType);

                setFavoriteMarketsIds(storage[sportId]);
                set(
                    feed === Feed.prematch
                        ? BrowserStorageIdsEnum.FAVORITE_PREMATCH_MARKETS_IDS
                        : BrowserStorageIdsEnum.FAVORITE_LIVE_MARKETS_IDS,
                    JSON.stringify(storage),
                );
            }
        },
        [feed, get, getUpdatedFavorites, set, sportId],
    );

    return { gameData, addBet, onFavoriteItemClick, favoriteMarketsIds, markets };
};
