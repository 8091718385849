import { FC, useCallback, useContext } from "react";
import { HrefBehavior, TranslationScopes } from "@finbackoffice/enums";
import { useSiteConfig } from "@finbackoffice/site-core";
import { ILayoutsSubHeaderConfig, ISiteConfigLayouts } from "@finbackoffice/fe-core";
import Translate from "components/base/translate/Translate";
import Button from "components/base/button/Button";
import { CasinoToolsContext } from "contexts";
import ActiveLink from "components/base/active-link/ActiveLink";
import styles from "./sub-header.module.sass";

/* const TEMP_CONFIG: ILayoutsSubHeaderConfig[] = [
    {
        href: "/casino",
        href_behavior: HrefBehavior.Relative,
        tags: [CasinoGameTag.New],
        scopes: [SiteContentScopes.AllPages],
        id: "sh_1",
    },
]; */

const SubHeader: FC = () => {
    const siteLayoutsConfig = useSiteConfig<ISiteConfigLayouts>("layouts");
    const { handlePlayNow } = useContext(CasinoToolsContext);
    const isGame = useCallback(
        (href_behavior: HrefBehavior) =>
            [HrefBehavior.GameSlot, HrefBehavior.GameLiveCasino].includes(href_behavior),
        [],
    );

    const isLink = useCallback(
        (href_behavior: HrefBehavior) =>
            [HrefBehavior.External, HrefBehavior.Relative].includes(href_behavior),
        [],
    );

    const onButtonClick = useCallback(
        (link: ILayoutsSubHeaderConfig) => {
            const href = link.href;

            const isSlots = link.href_behavior === HrefBehavior.GameSlot;
            const gameId = href ? `${href}-mobile` : "";

            handlePlayNow(gameId, isSlots);
        },
        [handlePlayNow],
    );

    const renderLink = (link: ILayoutsSubHeaderConfig) => {
        if (isLink(link.href_behavior) && link.href) {
            if (link.href_behavior === HrefBehavior.Relative) {
                return (
                    <ActiveLink
                        key={link.id}
                        // onClick={linkClickHandler}
                        href={link.href}
                        activeClassName={styles.active}
                        className="banner-item">
                        <Translate
                            tid={`${link.id}_title`}
                            namespace={TranslationScopes.SubHeader}
                        />
                    </ActiveLink>
                );
            }
        } else if (isGame(link.href_behavior)) {
            return (
                <Button
                    key={link.id}
                    type="button"
                    onClick={() => onButtonClick(link)}
                    className="banner-item">
                    <Translate tid={`${link.id}_title`} namespace={TranslationScopes.SubHeader} />
                </Button>
            );
        }
        return null;
    };

    return (
        <div className={styles.subHeader}>
            <nav>
                {siteLayoutsConfig.subHeader.config.map(renderLink)}
                {/* <Link href="#">
                    <Svg
                        src="/common/mobile/base-icons/menu-sport.svg"
                        wrapper="span"
                        className="svg-icon"
                    />
                    <Translate tid="pageName_sport" />
                </Link>
                <Link href="#" className={styles.active}>
                    <Svg
                        src="/common/mobile/base-icons/menu-live.svg"
                        wrapper="span"
                        className="svg-icon"
                    />
                    <Translate tid="pageName_live" />
                </Link>
                <Link href="#">
                    <Svg
                        src="/common/mobile/base-icons/menu-casino.svg"
                        wrapper="span"
                        className="svg-icon"
                    />
                    <Translate tid="pageName_livecasino" />
                </Link>
                <Link href="#">
                    <Svg
                        src="/common/mobile/base-icons/submenu-aviator.svg"
                        wrapper="span"
                        className="svg-icon"
                    />
                    Aviator
                </Link>
                <Link href="#">
                    <Svg
                        src="/common/mobile/base-icons/submenu-promotions.svg"
                        wrapper="span"
                        className="svg-icon"
                    />
                    Promotions
                </Link> */}
            </nav>
        </div>
    );
};

export default SubHeader;
