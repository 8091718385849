import { TimeFilter } from "@finbackoffice/fe-core";
import {
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    createContext,
    useMemo,
    useState,
} from "react";

type CalendarDateState = {
    from: number;
    to: number;
};

type IPrematchContext = {
    calendarDate: CalendarDateState | null;
    timeFilter: TimeFilter | null;
    gameDoesNotExist: boolean;
    setTimeFilter: Dispatch<SetStateAction<TimeFilter | null>>;
    setGameDoesNotExist: Dispatch<SetStateAction<boolean>>;
    setCalendarDate: Dispatch<SetStateAction<CalendarDateState | null>>;
};

export const PrematchContext = createContext<IPrematchContext>(null as any);

export const PrematchProvider: FC<PropsWithChildren> = ({ children }) => {
    const [timeFilter, setTimeFilter] = useState<TimeFilter | null>(null);
    const [calendarDate, setCalendarDate] = useState<CalendarDateState | null>(null);
    const [gameDoesNotExist, setGameDoesNotExist] = useState(false);

    const value = useMemo(
        () => ({
            timeFilter,
            gameDoesNotExist,
            calendarDate,
            setTimeFilter,
            setGameDoesNotExist,
            setCalendarDate,
        }),
        [calendarDate, gameDoesNotExist, timeFilter],
    );

    return <PrematchContext.Provider value={value}>{children}</PrematchContext.Provider>;
};
