import { EventStatus } from "@finbackoffice/enums";
import { sortByScheduledTime } from "@finbackoffice/fe-core";
import { useWebsocketUpdates } from "@finbackoffice/site-core";
import { IClientError, IEventFeedResponse } from "@finbackoffice/websocket-client";
import { useCallback, useEffect, useMemo, useState } from "react";

const statuses = [EventStatus.NotActive, EventStatus.Live, EventStatus.Settled, EventStatus.Ended];

export const usePrematchTournament = (tournamentId: number) => {
    const [games, setGames] = useState<IEventFeedResponse[]>([]);
    const [loading, setLoading] = useState(false);
    const [gamesMap] = useState<Map<number, IEventFeedResponse>>(new Map());

    const gamesUpdateHandler = useCallback(
        (data: IEventFeedResponse[], error: IClientError) => {
            if (!error) {
                data.forEach((event) => {
                    if (statuses.indexOf(event.status) !== -1) {
                        gamesMap.delete(event.id);
                    } else {
                        gamesMap.set(event.id, event);
                    }
                });
                const games = Array.from(gamesMap.values());
                const sorted_games = games.sort(sortByScheduledTime);
                setGames(sorted_games);
                setLoading(false);
            } else {
                console.error(error);
            }
        },
        [gamesMap],
    );

    const getPayload = useMemo(
        () => ({
            tournament_id: tournamentId,
        }),
        [tournamentId],
    );

    useWebsocketUpdates("subscribe_tournament", gamesUpdateHandler, true, getPayload);

    useEffect(() => {
        return () => {
            if (tournamentId) {
                setLoading(true);
                setGames([]);
                gamesMap.clear();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tournamentId]);

    return { games, loading };
};
